import React, { useState } from 'react'
import {
  Action,
  Avatar,
  HStack,
  Icon,
  MoreBar,
  TableWidget,
  Tip,
  Token,
  VStack,
} from '@revolut/ui-kit'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/Table/hooks'
import { jobPostingsRequests } from '@src/api/jobPosting'
import { RowInterface } from '@src/interfaces/data'
import { getSelectCellConfig } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import {
  jobDescriptionActionColumn,
  jobDescriptionGenericNameColumn,
  jobDescriptionOfficeLocationsColumn,
  jobDescriptionPlatformsColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRemoteLocationsColumn,
} from '@src/constants/columns/jobDescription'
import { pathToFullUrlWithBaseUrl } from '@src/utils/router'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import kebabCase from 'lodash/kebabCase'
import { useGetCareerSettings, useGetJobPostingSettings } from '@src/api/settings'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import Tooltip from '@components/Tooltip/Tooltip'
import { Link } from 'react-router-dom'
import { useOnPublishClick } from '@src/pages/OnboardingChecklistV2/Jobs/utils'

type JobPostingPublishButtonProps = {
  data: JobPostingInterface
  refresh: () => void
  isPublishingEnabled?: boolean
}

const JobPostingPublishButton = ({
  data,
  isPublishingEnabled,
  refresh,
}: JobPostingPublishButtonProps) => {
  const { onPublishClick, publishLoading } = useOnPublishClick(refresh)
  return (
    <Action
      disabled={!isPublishingEnabled}
      pending={publishLoading}
      onClick={() => {
        onPublishClick(
          [data.id],
          data.status === PublishingStatuses.unpublished
            ? PublishingStatuses.fully_published
            : PublishingStatuses.unpublished,
        )
      }}
    >
      {data.status === PublishingStatuses.unpublished ? 'Publish' : 'Unpublish'}
    </Action>
  )
}

const row: (
  refresh: () => void,
  isPublishingEnabled?: boolean,
  isMonsterEnabled?: boolean,
) => RowInterface<JobPostingInterface> = (
  refresh,
  isPublishingEnabled,
  isMonsterEnabled,
) => ({
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...jobDescriptionGenericNameColumn,
      width: 250,
    },
    {
      ...jobDescriptionOfficeLocationsColumn,
      width: 250,
    },

    {
      ...jobDescriptionRemoteLocationsColumn,
      width: 250,
    },
    {
      ...jobDescriptionPublishedStatusColumn,
      width: 250,
      colors: data =>
        data.status === PublishingStatuses.fully_published
          ? Token.color.green
          : Token.color.foreground,
    },
    {
      ...jobDescriptionPlatformsColumn,
      width: 250,
      insert: ({ data }) => {
        const published = data.status === PublishingStatuses.fully_published

        return (
          <HStack gap="s-8">
            <Tooltip placement="top">
              <Icon
                size={20}
                name="Globe"
                color={published ? Token.color.foreground : Token.color.greyTone50}
              />
            </Tooltip>

            <Tooltip
              placement="top"
              text="It might take a couple of hours for job postings to be reflected on Monster"
            >
              <Avatar
                size={20}
                label="M"
                color={
                  published && isMonsterEnabled
                    ? Token.color.foreground
                    : Token.color.greyTone50
                }
              />
            </Tooltip>
          </HStack>
        )
      },
    },
    {
      ...jobDescriptionActionColumn,
      width: 250,
      insert: ({ data }) => (
        <HStack gap="s-8">
          <Action
            use="a"
            target="_blank"
            href={pathToFullUrlWithBaseUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
              id: `${kebabCase(data.name)}-${data.public_id}`,
            })}
          >
            Preview
          </Action>
          <JobPostingPublishButton
            data={data}
            isPublishingEnabled={isPublishingEnabled}
            refresh={refresh}
          />
        </HStack>
      ),
    },
  ],
})

export const JobsPreview = () => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<JobPostingInterface>>()
  const table = useTable(jobPostingsRequests)
  const { data: careersSettings } = useGetCareerSettings()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { onPublishClick, publishLoading } = useOnPublishClick(table.refresh)

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => r.id)
    }

    if (selectedData?.isAllSelected) {
      return table.data
        ?.map(r => r.id)
        .filter(r => !selectedData.excludeListIds.has(`${r}`))
    }

    return []
  }

  return (
    <VStack gap="s-16">
      {!careersSettings?.publish_career_website && (
        <Tip>
          <Tip.Avatar>
            <Avatar color={Token.color.orange} useIcon="ExclamationTriangle" />
          </Tip.Avatar>
          <Tip.Title>Setup Publishing</Tip.Title>
          <Tip.Description>
            To make your jobs visible to candidates, you have to configure career website
            and job boards first. Press “Publishing settings” to start
          </Tip.Description>
        </Tip>
      )}

      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            {selectedData?.someSelected ? (
              <>
                <MoreBar.Action
                  useIcon="Globe"
                  pending={publishLoading}
                  onClick={() => {
                    onPublishClick(getSelectedItems(), PublishingStatuses.fully_published)
                  }}
                >
                  Publish
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="EyeHide"
                  pending={publishLoading}
                  variant="negative"
                  onClick={() => {
                    onPublishClick(getSelectedItems(), PublishingStatuses.unpublished)
                  }}
                >
                  Unpublish
                </MoreBar.Action>
              </>
            ) : (
              <MoreBar.Action
                useIcon="Gear"
                use={Link}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={getLocationDescriptor(ROUTES.SETTINGS.CAREER_PAGE_ONBOARDING)}
                hasDot={!careersSettings?.publish_career_website}
              >
                Publishing settings
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <SelectTableWrapper
            enabled={!!careersSettings?.publish_career_website}
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
          >
            <AdjustableTable
              name={TableNames.OnboardingJobsPreview}
              row={row(
                table.refresh,
                careersSettings?.publish_career_website,
                jobPostingSettings?.enable_monster_integration,
              )}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
